<template>
  <el-dialog
    custom-class="email_loginDialog"
    :visible.sync="email_loginAndRegisterDialog"
    :top="'0'"
    :close-on-click-modal="false"
  >
    <div class="dialogBox" :style="dialogBoxStyle">
      <div
        class="dialogHeader"
        v-resize-ob="windowResize"
        :style="{
          'padding-left': `${realTimeComputing(65)}px`,
          height: `${realTimeComputing(105)}px`,
        }"
      >
        <el-image
          :style="{
            width: `${realTimeComputing(50)}px`,
            height: `${realTimeComputing(50)}px`,
          }"
          :src="
            require('../../../../assets/images/email/loginDialog/email_logo.png')
          "
          :fit="'fill'"
        ></el-image>
        <div
          class="logo_title"
          :style="{
            'font-size': `${realTimeComputing(24)}px`,
            'margin-left': `${realTimeComputing(8)}px`,
          }"
        >
          {{ $t("email_logoTitle") }}
        </div>
        <div class="headerOperate" :style="headerOperateStyle">
          <div
            class="buttonOuter"
            @click.stop="changeSize(1)"
            v-if="sizeType == 0"
          >
            <SizeButton></SizeButton>
          </div>
          <div
            class="buttonOuter"
            @click.stop="changeSize(0)"
            v-if="sizeType == 1"
          >
            <SizeButton :buttonType="'full'"></SizeButton>
          </div>
          <div
            @click.stop="close"
            class="icon-x iconfont_Me iconStyle buttonOuter"
          ></div>
        </div>
      </div>
      <div class="dialogBody">
        <div class="background_image">
          <el-image
            style="width: 100%;"
            :src="
              require('../../../../assets/images/email/loginDialog/background_image.png')
            "
            :fit="'cover'"
          ></el-image>
          <div
            class="background_image_mask mask_1"
            :style="{
              top: `${realTimeComputing(180)}px`,
              left: `${realTimeComputing(173)}px`,
              'max-width': `${realTimeComputing(150)}px`,
            }"
          >
            <div
              class="mask_text"
              :style="{
                'font-size': `${realTimeComputing(22)}px`,
                'line-height': `${realTimeComputing(26)}px`,
              }"
            >
              {{ $t("emailRegister_background_text_1") }}
            </div>
            <img
              class="mask_icon"
              :style="{
                height: `${realTimeComputing(16)}px`,
                'margin-top': `${realTimeComputing(8)}px`,
              }"
              :src="
                require('../../../../assets/images/email/loginDialog/background_icon_1.png')
              "
            />
          </div>
          <div
            class="background_image_mask mask_2"
            :style="{
              top: `${realTimeComputing(322)}px`,
              left: `${realTimeComputing(263)}px`,
              'max-width': `${realTimeComputing(300)}px`,
            }"
          >
            <div
              class="mask_text"
              :style="{
                'font-size': `${realTimeComputing(26)}px`,
                'line-height': `${realTimeComputing(30)}px`,
              }"
            >
              {{ $t("emailRegister_background_text_2") }}
            </div>
            <img
              class="mask_icon"
              :style="{
                height: `${realTimeComputing(18)}px`,
                'margin-top': `${realTimeComputing(16)}px`,
              }"
              :src="
                require('../../../../assets/images/email/loginDialog/background_icon_2.png')
              "
            />
          </div>
          <div
            class="background_image_mask mask_3"
            :style="{
              top: `${realTimeComputing(160)}px`,
              left: `${realTimeComputing(520)}px`,
              'max-width': `${realTimeComputing(135)}px`,
            }"
          >
            <div
              class="mask_text"
              :style="{
                'font-size': `${realTimeComputing(22)}px`,
                'line-height': `${realTimeComputing(26)}px`,
              }"
            >
              {{ $t("emailRegister_background_text_3") }}
            </div>
            <img
              class="mask_icon"
              :style="{
                height: `${realTimeComputing(19)}px`,
                'margin-top': `${realTimeComputing(10)}px`,
              }"
              :src="
                require('../../../../assets/images/email/loginDialog/background_icon_3.png')
              "
            />
          </div>
        </div>
        <div class="loginWindow">
          <Login v-if="emailRegisterCurrent == 'login'" />
          <Register v-if="emailRegisterCurrent == 'register'" />
          <SecurityVerification
            v-if="emailRegisterCurrent == 'selectSecurityType'"
          />
          <SecureBox v-if="showSecureBox" />
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import Login from "./login.vue";
import Register from "./register";
import SecurityVerification from "../security/setupSecurityVerification.vue";
import SecureBox from "../security/secureBox.vue";
import SizeButton from "@/newVersion/components/im_size_button";
export default {
  components: {
    Login,
    Register,
    SecurityVerification,
    SecureBox,
    SizeButton,
  },
  name: "Email_loginDialog",
  computed: {
    emailRegisterCurrent() {
      return this.$store.state.melinked_email.emailRegisterCurrent;
    },
    showSecureBox() {
      let views = ["phone", "email", "QA"];
      return views.includes(this.emailRegisterCurrent);
    },
    email_loginAndRegisterDialog() {
      return this.$store.state.melinked_email.email_loginAndRegisterDialog;
    },
    current_popup_display_status() {
      return this.$store.state.melinked_email.current_popup_display_status;
    },
    headerOperateStyle() {
      return {
        top: `${this.realTimeComputing(26)}px`,
        right: `${this.realTimeComputing(30)}px`,
        "font-size": `${this.realTimeComputing(14)}px`,
      };
    },
    dialogBoxStyle() {
      return {
        width: this.sizeType == 0 ? "75vw" : "100vw",
        height: this.sizeType == 0 ? "44.7vw" : "100vh",
      };
    },
  },
  mounted() {
    this.$store.commit(
      "setEmailRegisterCurrentHistory",
      this.current_popup_display_status
    );
    if (this.current_popup_display_status != "login") {
      this.$store.commit("setCurrent_popup_display_status", "login");
    }
  },
  data() {
    return {
      widthListener: 0,
      originalWidth: 1440,
      sizeType: 0,
    };
  },
  methods: {
    changeSize(size) {
      this.sizeType = size;
    },
    realTimeComputing(actualValue) {
      const countWidth = Math.ceil(
        (actualValue / this.originalWidth) * this.widthListener
      );
      return countWidth > actualValue ? actualValue : countWidth;
    },
    windowResize(ob) {
      this.widthListener = ob.width;
    },
    close() {
      this.$store.commit("setEmail_loginAndRegisterDialog", false);
      this.$nextTick(() => {
        Object.assign(this.$data, this.$options.data.call(this));
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.email_loginDialog
  .dialogBox
    min-width: 1000px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .dialogHeader
      width: 100%;
      max-height: 100px;
      max-width: 1440px;
      min-height: 70px;
      flex-shrink: 0;
      border-bottom: 1px solid #D9D9D9;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      .logo_title
        color: #292D32;
        font-weight: 500;
      .headerOperate
        position: absolute;
        color: #292D32;
        width: 50px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 10;
        .buttonOuter
          height: 16px;
          width: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        .iconStyle
          font-size: 16px;
    .dialogBody
      width: 100%;
      max-width: 1440px;
      max-height: 810px;
      flex: 1;
      min-height: 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .background_image
        width: 100%;
        overflow: hidden;
        position: relative;
        user-select: none;
        .background_image_mask
          position: absolute;
          z-index: 10;
          .mask_text
            font-weight: 700;
            word-break: keep-all;
            text-transform: uppercase;
          .mask_icon
            display: block;
        .mask_1
          color: #0071BC;
        .mask_2
          color: #D64569;
        .mask_3
          color: #F09626;
      .loginWindow
        position: absolute;
        z-index: 1;
        width: 27.08vw;
        height: 30.2vw;
        max-width: 520px;
        min-width: 360px;
        max-height: 580px;
        min-height: 403px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
        top: 50%;
        transform: translate3d(0,-50%,0);
        right: 2.6vw;
</style>
<style lang="stylus">
.email_loginDialog
  width: auto !important;
  max-width: 100%;
  margin: 0 !important;
  .el-dialog__header
    display: none;
  .el-dialog__body
    padding: 0;
  .el-dialog__footer
    display: none;
</style>
