var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "custom-class": "email_loginDialog",
        visible: _vm.email_loginAndRegisterDialog,
        top: "0",
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.email_loginAndRegisterDialog = $event
        }
      }
    },
    [
      _c("div", { staticClass: "dialogBox", style: _vm.dialogBoxStyle }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "resize-ob",
                rawName: "v-resize-ob",
                value: _vm.windowResize,
                expression: "windowResize"
              }
            ],
            staticClass: "dialogHeader",
            style: {
              "padding-left": `${_vm.realTimeComputing(65)}px`,
              height: `${_vm.realTimeComputing(105)}px`
            }
          },
          [
            _c("el-image", {
              style: {
                width: `${_vm.realTimeComputing(50)}px`,
                height: `${_vm.realTimeComputing(50)}px`
              },
              attrs: {
                src: require("../../../../assets/images/email/loginDialog/email_logo.png"),
                fit: "fill"
              }
            }),
            _c(
              "div",
              {
                staticClass: "logo_title",
                style: {
                  "font-size": `${_vm.realTimeComputing(24)}px`,
                  "margin-left": `${_vm.realTimeComputing(8)}px`
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("email_logoTitle")) + "\n      "
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "headerOperate", style: _vm.headerOperateStyle },
              [
                _vm.sizeType == 0
                  ? _c(
                      "div",
                      {
                        staticClass: "buttonOuter",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changeSize(1)
                          }
                        }
                      },
                      [_c("SizeButton")],
                      1
                    )
                  : _vm._e(),
                _vm.sizeType == 1
                  ? _c(
                      "div",
                      {
                        staticClass: "buttonOuter",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changeSize(0)
                          }
                        }
                      },
                      [_c("SizeButton", { attrs: { buttonType: "full" } })],
                      1
                    )
                  : _vm._e(),
                _c("div", {
                  staticClass: "icon-x iconfont_Me iconStyle buttonOuter",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.close.apply(null, arguments)
                    }
                  }
                })
              ]
            )
          ],
          1
        ),
        _c("div", { staticClass: "dialogBody" }, [
          _c(
            "div",
            { staticClass: "background_image" },
            [
              _c("el-image", {
                staticStyle: { width: "100%" },
                attrs: {
                  src: require("../../../../assets/images/email/loginDialog/background_image.png"),
                  fit: "cover"
                }
              }),
              _c(
                "div",
                {
                  staticClass: "background_image_mask mask_1",
                  style: {
                    top: `${_vm.realTimeComputing(180)}px`,
                    left: `${_vm.realTimeComputing(173)}px`,
                    "max-width": `${_vm.realTimeComputing(150)}px`
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mask_text",
                      style: {
                        "font-size": `${_vm.realTimeComputing(22)}px`,
                        "line-height": `${_vm.realTimeComputing(26)}px`
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("emailRegister_background_text_1")) +
                          "\n          "
                      )
                    ]
                  ),
                  _c("img", {
                    staticClass: "mask_icon",
                    style: {
                      height: `${_vm.realTimeComputing(16)}px`,
                      "margin-top": `${_vm.realTimeComputing(8)}px`
                    },
                    attrs: {
                      src: require("../../../../assets/images/email/loginDialog/background_icon_1.png")
                    }
                  })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "background_image_mask mask_2",
                  style: {
                    top: `${_vm.realTimeComputing(322)}px`,
                    left: `${_vm.realTimeComputing(263)}px`,
                    "max-width": `${_vm.realTimeComputing(300)}px`
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mask_text",
                      style: {
                        "font-size": `${_vm.realTimeComputing(26)}px`,
                        "line-height": `${_vm.realTimeComputing(30)}px`
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("emailRegister_background_text_2")) +
                          "\n          "
                      )
                    ]
                  ),
                  _c("img", {
                    staticClass: "mask_icon",
                    style: {
                      height: `${_vm.realTimeComputing(18)}px`,
                      "margin-top": `${_vm.realTimeComputing(16)}px`
                    },
                    attrs: {
                      src: require("../../../../assets/images/email/loginDialog/background_icon_2.png")
                    }
                  })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "background_image_mask mask_3",
                  style: {
                    top: `${_vm.realTimeComputing(160)}px`,
                    left: `${_vm.realTimeComputing(520)}px`,
                    "max-width": `${_vm.realTimeComputing(135)}px`
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mask_text",
                      style: {
                        "font-size": `${_vm.realTimeComputing(22)}px`,
                        "line-height": `${_vm.realTimeComputing(26)}px`
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("emailRegister_background_text_3")) +
                          "\n          "
                      )
                    ]
                  ),
                  _c("img", {
                    staticClass: "mask_icon",
                    style: {
                      height: `${_vm.realTimeComputing(19)}px`,
                      "margin-top": `${_vm.realTimeComputing(10)}px`
                    },
                    attrs: {
                      src: require("../../../../assets/images/email/loginDialog/background_icon_3.png")
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "loginWindow" },
            [
              _vm.emailRegisterCurrent == "login" ? _c("Login") : _vm._e(),
              _vm.emailRegisterCurrent == "register"
                ? _c("Register")
                : _vm._e(),
              _vm.emailRegisterCurrent == "selectSecurityType"
                ? _c("SecurityVerification")
                : _vm._e(),
              _vm.showSecureBox ? _c("SecureBox") : _vm._e()
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }